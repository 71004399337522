import { Component, OnInit, EventEmitter, Output, Input, ViewChild,  SimpleChanges  } from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { ServiceSiteService } from 'app/services/service-site.service';
import { AppConfig } from 'app/app.config';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-service-selecteur',
  templateUrl: './service-selecteur.component.html',
  styleUrls: ['./service-selecteur.component.scss'],
})
export class ServiceSelecteurComponent implements OnInit {


  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @ViewChild('serviceGrid') serviceGrid: DxDataGridComponent;
  @Output() serviceUpdated = new EventEmitter();
  @Input('selectedSite') selectedSite: any = undefined;
  @Input('selectedService') selectedService: any = undefined;
  @Input('defaultMessage') nomService: any = "Service";

 // @Input('dataSource') serviceDataSource: any = null;

  

  // datasources
   serviceDataSource: any;



  _gridBoxValue: number = 1;
  _gridSelectedRowKeys: number[] = [1];



  constructor(private serviceSiteService: ServiceSiteService, public translate: TranslateService) {
    this.serviceDataSource = serviceSiteService.getRestDataSource();
  }

  ngOnInit() {
    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
     if (this.selectedService == 0) this.selectedService = undefined;
    //  console.log("site-selecteur.component : ngOnInit()");
  }

  ngAfterViewInit() {
    // Trick for enabling site refreshing when changing client for the very first time
    /*
     setTimeout(() => {
      this.dropBox.instance.open();
     this.dropBox.instance.close();
    }, 100);
    */
  }

  reset() {
    this.selectedService = undefined;
  }




  ngOnChanges(changes: SimpleChanges) {

 //   console.log("siteSelecteur:ONCHANGE:", changes);
    //const client: SimpleChange = changes.selectedClient;

    if (changes.selectedService) {
      if (changes.selectedService.currentValue == 0 || changes.selectedService.currentValue == null) {

        this.selectedService = undefined;
        if (this.serviceGrid && this.serviceGrid.instance) this.serviceGrid.instance.clearSelection();
        this._gridBoxValue = 1;
        this._gridSelectedRowKeys = [0];
      }
    }
    if (changes.selectedSite) {
      this.serviceSiteService.sitePredefini[0] = this.selectedSite;

      if (this.serviceGrid && this.serviceGrid.instance && this.selectedSite ) {
        // console.log("reponsable-selecteur: this.selectedSite=", this.selectedSite);
        this.serviceGrid.instance.filter(["site.id", "=", parseInt(this.selectedSite)]);
        this.serviceGrid.instance.getDataSource().reload();
      }
    }

    if (changes.defaultMessage)
      this.nomService = changes.defaultMessage;

  }



  dropdown_syncGridSelection(event) {

    if (!this.serviceGrid) return;

    if (event.value === null) {
      if (AppConfig.settings.trace) console.log("Service CLEARED !!!");
      this.serviceGrid.instance.clearSelection();
      this.serviceUpdated.emit(undefined);
      this.nomService = "Aucun";
      this.selectedService = undefined;
      this._gridBoxValue = 1;
      this._gridSelectedRowKeys = [0];
     }
    if (!this.serviceGrid) {
      this.serviceGrid.instance.clearSelection();
    } else {
     // this.serviceGrid.instance.clearSelection();
      // If this is not commented, then selection is sysematically cleared
      ;// this.selectTreeList.instance.selectRowsByIndexes(this.treeListValue);
    }
  }

  dropdown_gridItemSelectionChanged(event) {
    if (!this.serviceGrid) return;

    // console.log("dropdown_gridItemSelectionChanged", event);
    this.selectedService = this.serviceGrid.instance.getSelectedRowKeys();

    this._gridBoxValue = this.selectedService.length && this.selectedService[0] || null;
    this._gridSelectedRowKeys = this.selectedService;

    if (this.dropBox && this.dropBox.instance && event.selectedRowKeys.length > 0) {
      this.dropBox.instance.close();
      this.nomService = event.selectedRowsData[0].nomService;
    }
    this.serviceUpdated.emit(this.serviceGrid.instance.getSelectedRowsData()[0]);

  }


  get gridBoxValue(): number {
    return this._gridBoxValue;
  }

  set gridBoxValue(value: number) {
    this._gridSelectedRowKeys = value && [value] || [];
    this._gridBoxValue = value;
  }

  get gridSelectedRowKeys(): number[] {
    return this._gridSelectedRowKeys;
  }

  set gridSelectedRowKeys(value: number[]) {
    this._gridBoxValue = value.length && value[0] || null;
    this._gridSelectedRowKeys = value;
  }

  gridBox_displayExpr(item) {
    return item && (item.nomService);
  }

}
